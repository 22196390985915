body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,  "Montserrat", sans-serif, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace,"Montserrat";
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-Regular.woff") format("truetype");
}

