// COLORS

$baseColor: #161c24;
$themePrimaryColor: #dab46e;
$themeSecondaryColor: #c0954a;
$cardColor: #242b35;
$purpleCard: linear-gradient(180deg, #543883 0%, #443065 100%);
$borderColor: rgba(255, 255, 255, 0.125);

$siderExpandedWidth: 280px;
$siderCollapsedWidth: 80px;
$borderRadiusBase: 5px;
$spacer: 1.5rem;
$transition: 0.15s ease-in-out;
$zIndex: 100;

// FONTS
$tableFontSize: 1rem;
$fontPrimary: Roboto, sans-serif;
$fontFuturistic: "Aber Mono";

// COMPONENTS
$togglerSize: 30px;
$logoSize: 150px;
