html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;

}

.container {
  overflow: scroll;
  padding-bottom: 80px !important;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  // background-color: #000000 !important;
  border: 1px solid;
  border-image-source: conic-gradient(from 180deg at 50% 50%, #343434 -41.25deg, #d7d7d7 60deg, #434343 142.5deg, #dcdcdc 228.75deg, #343434 318.75deg, #d7d7d7 420deg);
  background-color: #111315 !important;
  background-image: url("./assets/img/container-img.png") !important;
  // mix-blend-mode: soft-light;
  // opacity: 0.24;
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100vh !important;
}

.font-sniglet {
  font-family: "Montserrat" !important;
}

.clr_black_new {
  color: #1b1b1b !important;
}

.bg_black_new {
  background-color: #1b1b1b !important;
}

.primaryColor {
  color: #dab46e;
}

.bg_primary {
  background-color: #dab46e !important;
  border-color: #dab46e !important;
}

.cursor_pointer {
  cursor: pointer;
  background-color: transparent !important;
}

.cursor_pointer:hover {
  background-color: grey;
  opacity: 0.5;
}

.centered_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

.font-400 {
  font-weight: 400;
}

.font-700 {
  font-weight: 700;
}

.padding_0 {
  padding: 0 !important;
}

.pl_0 {
  padding-left: 0px !important;
}

.ml_0 {
  margin-left: 0px !important;
}

.mr_0 {
  margin-right: 0px !important;
}

@for $i from 0 through 50 {
  .custom-font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 50 {
  .custom-padding-#{$i} {
    padding: #{$i}px !important;
  }
}

@for $i from 0 through 50 {
  .c-mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

@for $i from 0 through 50 {
  .c-mr-#{$i} {
    margin-right: #{$i}px !important;
  }
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.text_center {
  text-align: center;
}

.whiteLabeledListItem {
  height: 60px;
  background-color: #fff !important;
  color: #000;
  border-radius: 8px !important;
}

.clr_black {
  color: #000000 !important;
}

.bg_black {
  background-color: #000000 !important;
}

.bg_img_chigi {
  border: 1px solid;
  border-image-source: conic-gradient(from 180deg at 50% 50%, #343434 -41.25deg, #d7d7d7 60deg, #434343 142.5deg, #dcdcdc 228.75deg, #343434 318.75deg, #d7d7d7 420deg);
  background-color: #111315 !important;
  background-image: url("./assets/img/Group 413.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.bg-pink-header {
  background: #160321;
}

.bg_white {
  background-color: #ffffff !important;
}

.round {
  border-radius: 50%;
}

.white-small-box {
  background: #ffffff;
  border-radius: 20px;
  color: black;
  width: 80px;
  height: 28px;
  padding: 4px;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.implementation_box {
  height: 77px;
  width: 232px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 10px !important;
}

.txt_decoration_none {
  text-decoration: none !important;
}

.d_flex {
  display: flex;
}

.justify_center {
  justify-content: center;
}

.justify_end {
  justify-content: flex-end;
}

.justify_start {
  justify-content: flex-start;
}

.justify_between {
  justify-content: space-between;
}

.align_center {
  align-items: center;
}

.custom_input_wrap {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
}

.img_29 {
  width: 29px;
  height: 29px;
}

.csvBtn {
  height: 28px;
  width: 142px;
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: -3px;
  border-radius: 5px;
}

.header_layout {
  height: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.border-radius-8 {
  border-radius: 8px;
}

.connectBtn1 {
  width: 150px;
  height: 40px;
  // border: none;
  font-weight: 700;
  color: #000000 !important;
  // border: 1px solid;
}

.connectBtn {
  width: 150px;
  height: 40px;
  border: none;


  font-weight: 700;
}

.setupBtn {
  width: 195px;
  height: 40px;
  border: none;
  font-family: "Montserrat" !important;
  color: #ffffff !important;
  background-color: #FE6E21 !important;
}

.gotodashboard {
  background-color: white !important;
  width: 195px !important;
}

.seed-round-btn-width {
  max-width: 95% !important;
}

.user_dashboard_cards {
  background-color: #443065 !important;
  border-radius: 8px !important;
  box-shadow: 0px 0.2px 0.2px 0.2px #4a4a4a;
}

.wallet_address_card {
  height: 40px;
  width: 300px;
  border-radius: 8px;
  background: #FFFFFF;
  margin-right: -10px;
  padding-left: 11px;
}

.BNB_card {
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid#FE6E21;
  height: 30px;
  width: 90px;
  margin-left: 8px;
}

.loader {
  border: 10px solid #b4b3b3;
  border-top: 10px solid #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-black {
  border: 10px solid;
  border-top: 10px solid #b4b3b3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vesting-small-cards {
  // background-image: url("./assets/img/vesting-small-cards.png");
  background: 
  #05060C !important;
  width: 100%;
  background-repeat: round;
  padding: 1.5rem;
  border-radius: 10px 20px !important;
  border: 0.5px solid #FE6E21;
}

.bg_purple {
  background: #FE6E21 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
}

.bg_claim {
  background: linear-gradient(180deg, #25e8d8 0%, #1cbcaf 100%) !important;
}

.active-page-btn {
  color: #ffffff;
  height: 28px;
  width: 28px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
}

.footer {
  bottom: 0;
  padding-right: 40px;
  width: 100%;
  margin-bottom: 2rem;
}

.transparent-btn {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}

.tooltip {
  margin-bottom: 15px;
}

.padding {
  padding-top: 13px;
}

.sample {
  text-decoration: underline;
  font-size: 13px;
  padding: 0 7px 0 7px;
}

.connect-button-hide {
  display: none !important;
}

.simpletoken-width {
  min-width: 100% !important;
}

.clifftokenbtn {
  min-width: 100% !important;
  background-color: #FFFFFF !important;
  opacity: 1.5;
}

.cliftoken-width {
  max-width: 80% !important;
}

.noncliff-btn {
  justify-content: space-between;
  display: flex !important;
  max-width: 4% !important;
}

.noncliff-btn-d {
  padding: 0 10px 0 20px;
}

.vestingdetails-cliff {
  font-size: 25px;
  font-weight: 800;
}

.showButton {
  padding-inline-end: 20%;
}

.cliffbtn {
  width: 100% !important;
}

.img-btn-cliffwrapper {
  width: 20px !important;
  height: 20px !important;
}

.connect-wallet {
  text-align: center;
  line-height: 25px;
}

.connect-button-hide {
  display: none !important;
}

// desktop dialogbox
.transaction {
  text-decoration: underline !important;
  cursor: pointer;
  margin-left: 51%;
  margin-top: 8%;
  font-size: 18px;
  font-weight: 700;
}

.transactionprocessing {
  text-decoration: underline !important;
  margin-left: -10%;
  margin-top: 5%;
  font-size: 16px;
  font-weight: 700;
}

.copy-desktop {
  margin-top: 20%;
}

.copyprocessing-desktop {
  margin-top: 4%;
  margin-left: -14%;
}

//
.transaction-success {
  margin-left: 50px;
}

.cliffbtn-bg {
  background-color: white !important;
}

// for new card design
.vesting-option-card {
  background-color: #160321 !important;
  padding: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 25px;
  overflow-x: none;
  border: 0.5px solid #FE6E21;
  word-wrap: break-word;
  background-position: center top;
  border-radius: 20px 40px 20px 40px;
}

/// add for mobile design
.img-btn-cliffwrapper {
  width: 20px !important;
  height: 20px !important;
}

.connect-button-hide {
  display: none !important;
}

.cursor_pointer:hover {
  background-color: grey;

}

.copy-btn-color {
  background-color: transparent;
  border: 0px;
}

.dashboard-toggler {
  background-color: #0F121E !important;
  width: 309px !important;
  height: 309px;
  background-repeat: round;
  border: 0.5px solid  #FE6E21;
  position: absolute;
  left: 10%;
  right: 13.79%;
  top: 11.34%;
  bottom: 55.29%;
  border: 10px 20px 10px 20px;
}

.toggler-data {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.BNB_card-toggle {
  background-color: transparent !important;
  width: 194px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0F121E;
  border: 1px solid  #FE6E21;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 1%;
}

.cross-btn {
  height: 12px;
  width: 12px;
  margin-right: 40px;
  border-radius: 0px;
}

.myaccount-card {
  margin-top: 20px;
  margin-left: 45px;
  font-size: 20px;
  display: flex;
}

.account-btn {
  margin-top: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 193px;
  height: 40px;
  margin-left: 19%;
}

.clifftokenbtn {
  min-width: 100%;
  background-color: #ffffff !important;
}

.contractlogo-toggler {
  display: flex;
  margin-bottom: 10px;
  background: #1A1D1F;
  border-radius: 10px;
  width: 194px;
  height: 40px;
  padding-top: 5%;
  padding-left: 5%;
}

.bnbaccount-toggler {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .seed-section {
    float: left;
    font-size: 12px !important;
    width: 190px !important;
    clear: left;
  }

  .vesting-option-card {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .seedcard-data {
    margin-top: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    margin-top: -95px !important;
    display: grid;
    gap: 5px;
    justify-content: flex-end;
    margin-left: 30px;
  }
}

@media screen and (max-width: 480px) {
  .pagination {
    margin-top: 20px;
    margin-right: 100px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 768px) {
  .claim-loader {
    font-size: 15px !important;
    line-height: 29px;
    font-weight: 400;
    color: white;
    height: 60px !important;
    width: 334px !important;
    text-align: center;
  }
}

@media screen and (max-width: 680px) {
  .clif-btn {
    max-width: 150px !important;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    right: 21px;
    font-size: 14px;
    margin-left: 8px !important;
  }
}

@media screen and (max-width: 680px) {
  .claim-btn {
    width: 160px !important;
  }
}

// .toggler {
//   background-color: red !important;
// }
@media screen and (max-width: 680px) {
  .header-logo {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 78px !important;
    height: 46px !important;
  }
}

@media screen and (max-width:680px) {
  .approvedicon-mobile {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
  }

  .successfull-mobile {
    height: 30px;
    width: 130px;
    font-size: 24px;
    padding-top: 10px !important;
  }

  .transaction-mobile {
    margin-top: 10px !important;
  }

  .claimstatement-mobile {
    font-size: 16px !important;
    width: 275px !important;
    font-weight: 400;
  }

  .crosssuccesfull-btn {
    position: absolute;
    left: 87.31%;
    right: 5%;
    top: 5.74%;
    bottom: 83.65%;
  }
}

.addmetabtn {
  margin-left: 20px !important;
  border-color: black;
  background-color: #FFFFFF !important;
}

.tokenaddress {
  font-size: small;
}

.processing-admin {
  width: 100%;
  font-size: large;
}

// iron vest mobile design with px 340 to 760

@media screen and (min-width: 320px) and (max-width: 768px) {
  .toggler-imagemobile {
    width: 24px;
    height: 24px;
    margin-top: -62px;
    margin-right: -16px !important;
  }

  .processing-admin {
    width: 100%;
    font-size: small;
    margin-top: 38%;
  }

  // header mobile design
  .header_layout {
    height: 62px;
    padding-left: 10px;
    padding-right: 10px;
  }

  // next previous btn
  .active-page-btn {
    color: #ffffff;
    height: 28px;
    width: 28px;
    border-radius: 8px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 12px;
  }

  .nextprev-m {
    margin-right: 96px;
  }

  .prev-m {
    margin-right: 19px;
  }

  .myclaimmobile {
    // position: absolute;
    display: flex;
    margin-left: 20%;
    margin-top: 10%;
    margin-bottom: -2%;
  }

  // metamask dialog initial
  .connect-wallet {
    font-style: normal;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 25px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: #ffffff;
    margin-top: 3%;
    margin-bottom: 17% !important;
  }

  .centered_div {
    width: 334px !important;
    height: 300px;
    // top: 280px;
    font-size: 16px;
  }

  //
  //loader mobile
  .loader {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
  }

  .myclaimtoggler-mobile {
    width: 50% !important;
  }

  .claiming-mobile {
    height: 20px;
  }

  .successful-mobile {
    width: fit-content;
  }

  .claimingtext-mobile {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center;
    position: absolute;
    // left: 10.05%;
    right: 12.05%;
    top: 50.75%;
    bottom: 27.25%;
  }
.transactionbox-mobile {
  margin-top: 40px;
}
  // claiming statement logo mobile and dialog box
  .logomobile-s {
    width: 40px;
    height: 40px;
    margin-top: 0%;
    margin-right: 12%;
  }
  .foxyHead{
    width: 21.34px;
    height: 20px;
  }
  .successfuldialog-mobile {
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    width: 75%;
  }

  .transaction-mobile {
    font-size: 14px !important;
    margin-left: -5%;
    margin-top: 3% !important;
  }

  .claimstatement-mobile {
    font-size: 12px !important;
    width: 275px !important;
    font-weight: 400 !important;
    margin-left: -4%;
  }

  .transactionid-mobile {
    line-height: 21px;
    font-weight: 400;
    color: white;
    font-size: 12px !important;
    margin-left: 24%;
    margin-top: -5%;
  }

  .copy-mobile {
    margin-top: -18%;
    margin-left: 28%;
    position: absolute;
  }

  /// dialog btn for mobile
  .successfullybtn-mobile {
    display: table-footer-group;
  }

  .continuebtn-mobile {
    width: 120px !important;
    height: 30px !important;
    border-radius: 5px;
    margin-right: 3px;
    color: black !important;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #FFC465 !important;
    border-radius: 8px !important;
    margin-top: 67%;
    margin-left: 3%;
  }

  .Addmetabtn-mobile {
    background: #FFFFFF !important;
    border-radius: 8px;
    color: #000000 !important;
    border: 0px !important;
    margin-left: 3px;
    width: 77%;
    margin-left: 92% !important;
  }

  .crosssuccesfull-btn {
    position: absolute;
    left: 82.31%;
    right: 5%;
    top: 1.74%;
    bottom: 83.65%;
  }



  .f-dialog-main .f-dialog .f-dialog-content.bg-new-purple-popup {


    position: absolute;
    top: 20%;
    left: 10%;
    right: 10%;
    border-radius: 10px 30px !important;
    width: 320px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 60%;

  }

  .approvedicon-mobile {
    width: 60px !important;
    height: 60px !important;
    position: absolute;
  }

  .successfull-mobile {
    font-size: 15px;
    padding-top: 22px !important;
    font-size: 16px !important;
    margin-right: 12%important;
    margin-left: -5%;
  }

  // proceesing box mobile
  .transactiontext-mobile {
    font-style: normal;
    line-height: 30px;
    text-align: center;
    color: #FE6E21;
    font-weight: 700;
    font-size: 17px;
    line-height: 30px;
    position: absolute;
    // overflow-x: hidden;
    left: -0.51%;
    right: -0.51%;
    top: 44.75%;
    bottom: 42.75%;
  }

  .processingId-mobile {
    position: absolute;
    left: 2.08%;
    right: 32.83%;
    top: 61%;
    bottom: 30%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .copyprocessing-mobile {
    position: absolute;
    left: 90.04%;
    right: 18.3%;
    top: 73.15%;
    bottom: 24.22%;
  }

  .transactionidprocessing-mobile {
    position: absolute;
    left: 5.92%;
    right: 24.75%;
    top: 71%;
    bottom: 23%;
    font-size: 12px !important;
    font-weight: 400;
  }

  // seedcardbtn for mobile
  // .claimnoncliff-mobile {
  //   margin-left: 125%;
  //   width: 131px;
  //   font-size: 11px!important;
  // }
  // .claimcliff-mobile{
  //   width: 131px;
  //   font-size: 11px!important;
  // }
  .bothbtn-m {
    display: flex !important;
  }

  /// seed card mobile design
  .headingpool-m {
    font-size: 16px !important;
    color:  #FE6E21 !important;
    margin-bottom: -4%;

  }

  .gapmar-m {
    margin-top: 12px;
  }

  .gapmardata-m {
    margin-top: 14px;
  }

  /// SEED CARD BTN MOBILE
  .cliffvesting-btn {
    width: 106% !important;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    right: 26px;
    margin-left: 18px !important;
  }
}

// for desktop
.claiming-text {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  width: 70%;
}

//prev btn
.prev-m {
  margin-right: 19px;
}

// wallet connect
@media only screen and (min-device-width: 320px) and (max-device-width: 680px) {

  //cards
  .transaction-box-action-container {
    display: block;
    margin-top: 25px;
  }

  .qrtransactiontext-mobile {
    font-style: normal;
    line-height: 30px;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 17px;
    line-height: 30px;
    position: absolute;
    overflow-x: hidden;
    left: -0.51%;
    right: -0.51%;
    top: 15.75%;
    bottom: 42.75%;
  }

  .walletPromptBtn {
    width: 100% !important;
    height: 45px !important;
    background-color: transparent !important;
    color: white !important;
    font-weight: 800;
    border-color: #14acac;
    font-size: 1rem;
    margin-top: 20px;


    &:hover {
      background-color: #f5deb340 !important;
    }
  }

  .transaction-box-error {
    display: block;
    font-size: 15px;
    line-height: 1.2;
    margin-top: 10px;
  }

  .connect-wallet-select-m {
    margin-bottom: 24% !important;
  }

  //
  .safecodebox {
    font-size: 10px !important;
    width: 82% !important;
    position: absolute;
    margin-left: -5% !important;
    margin-top: 6%;
  }

  .safecodeboxtransaction {
    width: 60% !important;
    margin-left: 7% !important;
    position: absolute;
    margin-top: 35%;
  }

  .safecodeboxsubmit {
    width: 60% !important;
    margin-left: 7% !important;
    position: absolute;
    margin-top: 50%;
  }


  .dialog-connect-wallet {
    .f-dialog-header {
      display: none !important;
    }

    .f-list-connect-wallet {
      .f-list-item {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 60px;
        padding: 1.5rem;
        font-size: 1.5rem;
        background: #1c2229;
        margin-bottom: 1rem;
        font-weight: 700;
        margin-top: -37px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          max-width: 50px;
        }

        &:hover {
          transform: none !important;
          // background-color: #1cbcaf !important;
          background-color: #c69749 !important;
        }
      }
    }
  }
}

.walletPromptBtn {
  width: 100% !important;
  height: 52px !important;
  background-color: transparent !important;
  color: white !important;
  font-weight: 800;
  border-color: #14acac;
  font-size: 1rem;
  margin-top: 20px;


  &:hover {
    background-color: #f5deb340 !important;
  }
}

.transaction-box-error {
  display: block;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 10px;
}

.qrcodebox {
  font-size: small;
}

.safecodebox {
  font-size: 10px;
  width: 80%;
  margin-left: 10%;
}

.safecodeboxtransaction {
  width: 80%;
  margin-left: 10%;
}

//
.dashboard {
  width: 100%;
}
.f-btn.f-btn-whiteLabeled{
  color:white!important
}