@import "./variables";

.f-dialog-main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex * 100;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &:before {
    content: "";
    width: 100%;
    // height: 100vh;
    // padding-right: 17px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(black, 0.5);
  }

  .f-dialog {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    pointer-events: none;
    z-index: $zIndex * 1000000;
    display: flex;
    transform: translate(0, -25%);
    transition: all 0.3s ease-out;

    &.f-dialog-small {
      max-width: 375px;
    }

    &.f-dialog-medium {
      max-width: 500px;
    }

    &.f-dialog-large {
      max-width: 700px;
    }

    .f-dialog-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-clip: padding-box;
      outline: 0;
      border-radius: $borderRadiusBase * 4;
      transition: $transition;

      .close-dialog {
        cursor: pointer;

        svg {
          width: 15px;
        }
      }

      .f-dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem $spacer;

        h3 {
          font-size: 1.5rem;
          margin: 0.5rem 0;
        }

        &:not(.close-dialog) {
          width: 100%;
          text-align: center;
        }
      }

      .f-dialog-body {
        // padding: 2rem $spacer;
      }

      &.bg-dark {
        background: #212b36;
        color: white;
      }

      &.bg-new-purple-popup {

        background-color:
        #05060C!important;
        background-repeat: round !important;
        // background-color: transparent !important;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        overflow-x: none;
        word-wrap: break-word;
        padding: 0px 20px;
        border-radius: 10px 30px 10px 30px;
        border: solid 1px #FE6E21;
      }

      &.bg-whiteLabeled {
        background: #1b1b1b;
        color: white;
        margin: 23px;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
        border-bottom-right-radius: 10px !important;
      }

      &.bg-light {
        background: white;
        color: #212b36;

        .close-dialog {

          img,
          rect {
            filter: invert(0%) sepia(5%) saturate(7500%) hue-rotate(228deg) brightness(0%) contrast(106%);
          }
        }
      }

      &.bg-futuristic-primary {
        position: relative;
        border-radius: 0;
        padding: 1rem 0;

        .close-dialog {
          top: -0.25rem;
          right: 0;
          position: relative;
        }

        &:before {
          content: "";
          height: 70%;
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: $cardColor;
          border-width: 0 1px 0 1px !important;
          border-style: solid !important;
          border-color: #efcd8f !important;
          z-index: -1;
        }

        .img-futuristic {
          z-index: -1;

          img {
            position: absolute;
            width: 50.25%;
          }

          &.top {
            img {
              top: 0;

              &:first-child {
                left: 0;
              }

              &:last-child {
                right: 0;
                transform: scaleX(-1);
              }
            }
          }

          &.bottom {
            img {
              bottom: 0;

              &:first-child {
                left: 0;
                transform: rotate(-180deg) scaleX(-1);
              }

              &:last-child {
                right: 0;
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      &.bg-futuristic-secondary {
        position: relative;
        border-radius: 0;
        padding: 1rem;

        .close-dialog {
          top: -0.25rem;
          right: 0;
          position: relative;
        }

        .img-futuristic {
          z-index: -1;

          img {
            position: absolute;
            width: 100%;
          }

          &.top {
            img {
              top: 0;
              left: 0;
              transform: rotate(-180deg);
            }
          }

          &.bottom {
            img {
              bottom: 0;
              left: 0;
              transform: rotate(-180deg) scaleX(-1);
            }
          }
        }
      }
    }
  }

  &.show {
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;

    .f-dialog {
      top: 0;
      transform: translate(0, 0);

      &.f-dialog-center {
        align-items: center;
        min-height: calc(100% - (1.75rem * 2));
      }
    }
  }

  @media screen and (max-width: 680px) {
    .f-dialog {
      margin: 0 !important;
      height: 100vh !important;
      max-width: 100% !important;
      align-items: flex-end !important;

      .f-dialog-content {
        height: 80vh;
        max-height: 80vh !important;
        width: 100%;
        overflow-y: auto;

        .f-dialog-body {
          overflow-y: auto;
        }
      }
    }

    &.show {
      .f-dialog {
        bottom: 0 !important;

        .f-dialog-content {}
      }
    }
  }
}