.dialog-connect-wallet {
  .f-dialog-header {
    display: none !important;
  }

  .f-list-connect-wallet {
    .f-list-item {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 60px;
      padding: 1.5rem;
      font-size: 1.5rem;
      background: #1c2229;
      margin-bottom: 1rem;
      font-weight: 700;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        max-width: 50px;
      }

      &:hover {
        transform: none !important;
        // background-color: #1cbcaf !important;
        background-color: #c69749 !important;
      }
    }
  }
}

.f-dialog-main:before {
  height: 100% !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 10px ip !important;
  background: rgba(0, 0, 0, 0.765) !important;
}